<template>
<scale-box>
    <div class="hing-but" @click="back">返回</div>
     <div class="hing-chose">
               <el-checkbox v-model="type" label="is_xinxing" @change="selectType">战略性新兴产业的有效发明专利</el-checkbox>
              <el-checkbox v-model="type" label="is_overTen" @change="selectType">维持年限超10年的有效发明专利</el-checkbox>
              <el-checkbox v-model="type" label="is_tongzu" @change="selectType">在海外有同族专利权的有效发明专利</el-checkbox>
               <el-checkbox v-model="type" label="is_zy" @change="selectType">获得较高质押融资金额的有效发明专利</el-checkbox>
              <el-checkbox v-model="type" label="is_jl" @change="selectType">在获得国家科技奖或中国专利奖的有效发明专利</el-checkbox>
           </div>
	<div class="hing-box">
        <div class="hing-middle">
             <div class="right-list">
                <div v-for="(v,k) in tableData" :key="k" style="margin-bottom:30px;">
                    <div class="list-title">
                      <!-- <el-checkbox style="margin-right:8px;vertical-align: middle;"></el-checkbox> -->
                      <span>{{k+1}}.</span><span>【中国{{v.zllx}}】</span>
                      <span class="title-span1" style="cursor: pointer;" @click="tomore(v.shenqinghao)">{{v.shenqinghao}}</span><span style="cursor: pointer;" @click="topatent(v.zymc)">{{v.zymc}}</span>
                      <span class="title-span2" v-if="v.zlstatus== '02'">{{status[v.zlstatus]}}</span>
                      <span class="title-span2 ac1" v-if="v.zlstatus== '01'">{{status[v.zlstatus]}}</span>
                      <span class="title-span2 ac2" v-if="v.zlstatus== '03'">{{status[v.zlstatus]}}</span>
                      <!-- <span class="title-span2">47.0分</span> -->
                    </div>
                    <div class="list-detail">
                      <!-- <div><img src="" alt=""></div> -->
                      <div class="list-right">
                          <div class="right1">
                              <div>
                                  <div>
                                    <span>公开（公告）号：</span>
                                    <span class="colorcha">{{v.sqgkh}}</span>
                                  </div>
                                  <div>
                                    <span>申请日：</span>
                                    <span class="colorcha">{{v.sqr}}</span>
                                  </div>
                                  <div>
                                    <span>公开（公告）日：</span>
                                    <span class="colorcha">{{v.sqgkr}}</span>
                                  </div>
                              </div>
                              <div>
                                  <div>
                                     <span>申请（专利权）人：</span>
                                    <span>{{v.sqrmc}}</span>
                                  </div>
                                  <div>
                                     <span>主分类号：</span>
                                    <span class="colorcha">{{v.ipcflh}}</span>
                                  </div>
                                  <div>
                                     <span>代理机构：</span>
                                    <span>{{v.dljg}}</span>
                                  </div>
                              </div>
                              <div>
                                   <div>
                                     <span>是否战略性新兴产业的有效发明专利</span>
                                      （<i v-if="v.isXinxing == '1'" class="el-icon-check"></i><i v-else class="el-icon-close"></i>）
                                  </div>
                                   <div>
                                     <span>是否维持年限超10年的有效发明专利</span>
                                    （<i v-if="v.isOverTen == '1'" class="el-icon-check"></i><i v-else class="el-icon-close"></i>）
                                  </div>
                                   <div>
                                     <span>是否在海外有同族专利权的有效发明专利</span>
                                    （<i v-if="v.isTongzu == '1'" class="el-icon-check"></i><i class="el-icon-close"></i>）
                                  </div>
                              </div>
                              <div>
                                   <div>
                                     <span>是否获得较高质押融资金额的有效发明专利</span>
                                    （<i v-if="v.isZy == '1'" class="el-icon-check"></i><i v-else class="el-icon-close"></i>）
                                  </div>
                                   <div>
                                     <span>是否获得国家科技奖或中国专利奖的有效发明专利</span>
                                    （<i v-if="v.isJl == '1'" class="el-icon-check"></i><i v-else class="el-icon-close"></i>）
                                  </div>
                              </div>
                          </div>
                          <div style="clear:both;"></div>
                          <div style="width:1600px;font-size:18px;overflow:hidden;text-overflow: ellipsis;-webkit-line-clamp: 5;display: -webkit-box;-webkit-box-orient: vertical;">摘要：<span>{{v.zlzy}}</span></div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
       
            <div class="search-page">
                <div class="flex align-center" style="background-color:transparent;">
                  <el-pagination :current-page="from + 1" style="margin:0 auto;width: 1000px;text-align:center;padding-top:25px;font-size: 18px!important;"
                    :page-sizes="[20, 50, 100, 200]"
                    :page-size="size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    @size-change="pageSizeChanged"
                    @current-change="currentChanged"
                  />
                </div>
            </div>
		
        <div style="clear;both"></div>
	</div>
    </scale-box>
</template>
<script>
import scaleBox from "../../components/data/sclae-box";
export default {
  name: '',
  data(){
    return {
      tableData:[],
      from:0,
	  total:0,
	  size:20,
       status:{
        '01': '申请中',
        '02': '有效',
        '03': '无效',
      },
       province:"",
      city:"",
      county:"",
      level:"",
      word:this.$route.query.company,
      type:[]
    }
  },
 
  components: {
   scaleBox
  },
  watch:{
    
    
  },
  methods: {
	pageSizeChanged(val){
		this.size = val
		this.from =0;
	    this.getData1()
	},
     currentChanged(val){
		this.from = val - 1
		this.getData1()
	 },
     topatent(val){
       this.$router.push({
         path:'/searchDetail',
         query:{
            word:val
         }
      })
     },
     tomore(val){
         this.$router.push({
         path:'/moreDetail',
         query:{
            hao:val
         }
      })
     },
     back(){
        this.$router.push({
			path:"/patentData",
			query:{
				from:1
			}
		})
     },
    selectType(){
      // console.log(this.type)
      this.from = 0;
      this.getData1();
    },
	 getData1(){
                let name = [];
              name.push(this.word)
				this.$http.post(this.GLOBAL.new_url + '/patent/service/data/patentQueryHighValue',
					{
					"year":  '',
					"level": this.level,
                    "province": this.province,
                    "city": this.city,
                    "county":this.county,
                    "from": this.from,
                    "size":this.size,
                     "zymc":"",
                    "zlzy":"",
                    "fmr":[],
                    "sqrmc":name,
                    "lx":[],
                    "fenlei":[],
                    "filter":this.type 
					}
            ).then((res) => {
                // console.log(res)
                this.total = 0
				this.tableData.length = 0
				this.tableData.push(...res.data.data)
                this.tableData.map((x,y)=>{
                   if(x.sqr){
                        x.sqr = x.sqr.split("T")[0]
                    }
                        if(x.sqgkr){
                        x.sqgkr = x.sqgkr.split("T")[0]
                        } 
                })
				if (res.data.data.length != 0) this.total = res.data.data[0].total	
              } 
			)		
	 },
    
    
  },
   mounted() {
    this.province=localStorage.getItem('province');
      this.city=localStorage.getItem('city');
      this.county=localStorage.getItem('county');
      this.level=localStorage.getItem('level');
      if(this.county =="null"){
         this.county="";
      }
		if(this.level == "null"){
         this.level="";
      }
      this.getData1();
  
    },
    beforeCreate() {
            document.querySelector('body').setAttribute('style', 'background-color:#1C3E77;')
        },
}
</script>

<style scoped>
	.hing-box{
      width: 1920px;
      height: 1028px;
      margin: 16px auto  0;
      position: relative;
    }
    .hing-but{
        position: absolute;
        width: 120px;
        height: 40px;
        background: #002C8C;
        box-shadow: inset 0px 5px 15px 1px #1890FF;
        font-size: 20px;
        cursor: pointer;
        line-height: 40px;
        text-align: center;
        color: #FFFFFF;
        top:10px;
        right:30px;
        z-index: 100;
    }
    .hing-middle{
        width: 1920px;
        overflow-y: auto;
        height: 850px;
        margin-top: 80px;
        background: #FFFFFF;
    }
    .right-list{
        width: 1800px;
       padding: 32px 0 0 0;
        margin: 0 auto;
    }
  .list-title{
    font-size: 18px;
    margin-bottom: 25px;
  }
  .list-title :deep(.el-checkbox__inner){
    width: 24px;
    height: 24px;
  }
  :deep(.el-checkbox__inner::after){
    width: 8px!important;
    height: 18px!important;
    left: 6px!important;
}
.title-span1{
  color: #0958D9;
  margin: 0 32px;
}
.title-span2{
  width: 160px;
  height: 40px;
  line-height: 40px;
  margin-left: 32px;
  color: #FFFFFF;;
  text-align: center;
  vertical-align: middle;
  background: #2C7AF9;
  display: inline-block;
}
.ac1{
  background: #0FC109;
}
.ac2{
  background: #E51E1E;
}
.list-detail{
  height: 250px;
}
.list-detail>div{
  float: left;
}
.list-detail>div>img{
  width: 160px;
  height: 248px;
  border: 1px solid #707070;
}
.list-right{
  margin-left: 24px;
}
.right1{
  font-size: 18px;
  height: 110px;
}
.right1>div{
  float: left;
}
.right1>div>div{
  margin-bottom: 15px;
}
.right1>div>div>span:nth-child(2n){
  width: 280px;
  vertical-align: middle;
  display: inline-block;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
   /* border:1px solid red; */
}
.colorcha{
  color:#0958D9;
}
.search-page{
  margin-top: 15px;
  height: 70px;
  background: #FFFFFF;
}
:deep(.el-pagination) span{
  font-size: 18px!important;
 }
:deep(.el-pager li){
	font-size: 18px;
 }
 .flex :deep(.el-input__inner){
    font-size: 18px;
 }
 .hing-chose{
  position: absolute;
  top: 55px;
  left: 50%;
   width: 1800px;
   z-index: 200;
   transform: translate(-50%,0);
 }
 .hing-chose :deep(.el-checkbox__inner){
    width: 24px;
    height: 24px;
  }
  .hing-chose :deep(.el-checkbox__label){
    font-size: 18px;
    color: #d6d6d6;
    vertical-align: middle;
  }
</style>


